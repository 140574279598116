import { ApprovalStatusPolicy } from "constants/document/ApprovalStatusPolicy";
import { EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import moment from "moment";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useMemo } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DocumentPreviewTitleBar:React.FC = () => {
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewVM = useDocumentPreviewVM();
    const {selectedRows,searchStatus,searchCriteria, allFormState} = documentPreviewState;
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Title;
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();
    const {allowUpdate,userName} = anaInfoState;
    const allowReadDis = isPermissionExist(Permission.DOCUMENT_PREVIEW_DISAPPROVE, AclType.READ, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    const allowUpdateDis = isPermissionExist(Permission.DOCUMENT_PREVIEW_DISAPPROVE, AclType.UPDATE, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    const allowReadApp = isPermissionExist(Permission.DOCUMENT_PREVIEW_APPROVE, AclType.READ, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    const allowUpdateApp = isPermissionExist(Permission.DOCUMENT_PREVIEW_APPROVE, AclType.UPDATE, anaInfoState.defaultOperatingCompany, anaInfoState.allNbisivPermission);
    
    const handleSearch = useCallback(() => {
        documentPreviewVM.onSearchClick();
    }, [documentPreviewVM]);

    const handleEdit = useCallback(() => {
        documentPreviewVM.onEditClick();
    }, [documentPreviewVM]);
    
    const handleFinalize = useCallback(async () => {
        documentPreviewVM.onShowPrintAndSent(userName,selectedRows);
    }, [documentPreviewVM, selectedRows, userName]);

    const handleReject = useCallback(async () => {
        documentPreviewVM.onShowLoading();
        await documentPreviewVM.onReject(selectedRows).then(async (data) => {
                messageBarVM.showSuccess("Reject Success.");
                if(searchStatus !== ""){
                    documentPreviewVM.onGroupButtonClick(searchStatus).then((data) => {
                        documentPreviewVM.onHideLoading();
                    });
                }else{
                    await documentPreviewVM.searchDocPreviewData(searchCriteria).then((data) => {
                        documentPreviewVM.onHideLoading();
                    });
                }
            }).catch(error => {            
            messageBarVM.showError("Reject fail.");
            documentPreviewVM.onHideLoading();
        }).finally(() => {
            documentPreviewVM.onHideLoading();
        })
    }, [documentPreviewVM, messageBarVM, searchCriteria, searchStatus, selectedRows]);

    const handleApprove = useCallback(async () => {
        documentPreviewVM.onShowLoading();
        let rows = selectedRows.slice();
        const ids = rows.map(row => row.id);
        await documentPreviewVM.onApprove(ids as number[]).then(async (data) => {
            if(data){
                messageBarVM.showSuccess("Approve Success.");
            }else{
                messageBarVM.showError("Approve fail.");
            }
            if(searchStatus !== ""){
                documentPreviewVM.onGroupButtonClick(searchStatus).then((data) => {
                    documentPreviewVM.onHideLoading();
                });
            }else{
                await documentPreviewVM.searchDocPreviewData(searchCriteria).then((data) => {
                    documentPreviewVM.onHideLoading();
                });
            }
        }).catch(error => {            
            documentPreviewVM.onHideLoading();
        }).finally(() => {
            documentPreviewVM.onHideLoading();
        })
    }, [documentPreviewVM, messageBarVM, searchCriteria, searchStatus, selectedRows]);

    const handleDisapprove = useCallback(async () => {
        documentPreviewVM.onShowLoading();
        let rows = selectedRows.slice();
        const ids = rows.map(row => row.id);
        await documentPreviewVM.onDisapprove(ids as number[]).then(async (data) => {
            if(data){
                messageBarVM.showSuccess("Disapprove Success.");
            }else{
                messageBarVM.showError("Disapprove fail.");
            }
            if(searchStatus !== ""){
                documentPreviewVM.onGroupButtonClick(searchStatus).then((data) => {
                    documentPreviewVM.onHideLoading();
                });
            }else{
                await documentPreviewVM.searchDocPreviewData(searchCriteria).then((data) => {
                    documentPreviewVM.onHideLoading();
                });
            }
        }).catch(error => {            
            documentPreviewVM.onHideLoading();
        }).finally(() => {
            documentPreviewVM.onHideLoading();
        })
    }, [documentPreviewVM, messageBarVM, searchCriteria, searchStatus, selectedRows]);

    const handleRefresh = useCallback(async () => {
        documentPreviewVM.onShowLoading();
        let newSearchCriteria = null;
        newSearchCriteria = {...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA} 
        newSearchCriteria = { ...newSearchCriteria, 
            genBy: anaInfoState.userName, 
            genDateFrom: moment().subtract(10, "days").startOf("day").toDate(),
            genDateTo: moment().endOf("day").toDate(), 
        };
        documentPreviewVM.searchAllDocPreviewData();
        await documentPreviewVM.searchDocPreviewData(newSearchCriteria).then((data) => {            
            documentPreviewVM.onHideLoading();
        }).catch(error => {            
            documentPreviewVM.onHideLoading();
        }).finally(() => {
            documentPreviewVM.onHideLoading();
        })
    }, [anaInfoState.userName, documentPreviewVM]);

    const handlePreviewDoc = useCallback(() => {
        documentPreviewVM.previewDocReport(selectedRows, false);        
    }, [selectedRows, documentPreviewVM]);

    const handleDownloadDoc = useCallback(() => {
        documentPreviewVM.previewDocReport(selectedRows, true);          
    }, [documentPreviewVM, selectedRows]);

    const handlePreviewSupporting = useCallback(async () => {        
        await documentPreviewVM.previewSupportingReport(selectedRows, false).then((data) => {
            if (allFormState && allFormState["previewReportFail"]) {
                messageBarVM.showError(allFormState["previewReportFail"]?.toString());
            }
        });
    }, [documentPreviewVM, selectedRows, allFormState, messageBarVM]);

    const handleDownloadSupporting = useCallback(async () => {
        await documentPreviewVM.previewSupportingReport(selectedRows, true).then((data) => {
            if (allFormState && allFormState["previewReportFail"]) {
                messageBarVM.showError(allFormState["previewReportFail"]?.toString());
            }
        });            
    }, [documentPreviewVM, selectedRows, allFormState, messageBarVM]);

    const isDisable = () => {
        if (!documentPreviewState.isShowEditPanel && documentPreviewState.selectedRows.length > 0) return false;

        return true;
    }
    const isFinalizeDisable = () => {
        if (!documentPreviewState.isShowEditPanel && documentPreviewState.selectedRows.length > 0 
            && documentPreviewState.selectedRows.every(entity => ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) !== "WFA" && ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) !== "REJ")) return false;

        return true;
    }
    const isApproveDisable = () => {
        if (!documentPreviewState.isShowEditPanel && documentPreviewState.selectedRows.length > 0 
            && documentPreviewState.selectedRows.every(entity => ApprovalStatusPolicy().getApprovalStatusKeyByValue(entity.approvalStatus as string) === "WFA")) return false;

        return true;
    }

    const isEditDisable = () => {
        if (documentPreviewState.selectedRows.length > 0 && !documentPreviewState.isShowEditPanel) return false;

        return true;
    }
    
    const isInvPrevDisable = () => {
        if (!documentPreviewState.isShowEditPanel && documentPreviewState.selectedRows.length > 0) return false;

        return true;
    }

    const memoInvPrevMenu = useMemo(() => {
        return [
            {
                title: DOCUMENT_PREVIEW_CONSTANT.PREVIEW_DOC,
                disabled: false,
                command: () => {
                    handlePreviewDoc();
                }
            },
            {
                title: DOCUMENT_PREVIEW_CONSTANT.DOWNLOAD_DOC,
                disabled: false,
                command: () => {
                    handleDownloadDoc();
                }
            },
            {
                title: DOCUMENT_PREVIEW_CONSTANT.PREVIEW_SUP,
                disabled: false,
                command: () => {
                    handlePreviewSupporting();
                }
            },
            {
                title: DOCUMENT_PREVIEW_CONSTANT.DOWNLOAD_SUP,
                disabled: false,
                command: () => {
                    handleDownloadSupporting();
                }
            }
        ]
    }, [DOCUMENT_PREVIEW_CONSTANT.DOWNLOAD_DOC, DOCUMENT_PREVIEW_CONSTANT.DOWNLOAD_SUP, DOCUMENT_PREVIEW_CONSTANT.PREVIEW_DOC, DOCUMENT_PREVIEW_CONSTANT.PREVIEW_SUP,
         handleDownloadDoc, handleDownloadSupporting, handlePreviewDoc, handlePreviewSupporting])

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{DOCUMENT_PREVIEW_CONSTANT.INVOICE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-reload' disabled={documentPreviewState.isShowEditPanel} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <IconButton fileName='Icon-search' disabled={documentPreviewState.isShowEditPanel} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowUpdate) && <IconButton fileName='Icon-pen' disabled={isEditDisable()} size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
            <div className="add-seperator"/>
            <OverflowMenuButton disabled={isInvPrevDisable()} buttonLabel={DOCUMENT_PREVIEW_CONSTANT.INVOICE_PREVIEW_BUT} navigationList={memoInvPrevMenu} />         
            {((allowReadDis && allowUpdateDis) || (allowReadApp && allowUpdateApp)) && <div className="add-seperator"/>}
            {(allowReadDis && allowUpdateDis) && <HPHButton disabled={isApproveDisable()} label={DOCUMENT_PREVIEW_CONSTANT.DISAPPROVE_BUT} size={'Small'} theme={'Secondary'} onClick={handleDisapprove} />}
            {(allowReadApp && allowUpdateApp) && <HPHButton disabled={isApproveDisable()} label={DOCUMENT_PREVIEW_CONSTANT.APPROVE_BUT} size={'Small'} theme={'Secondary'} onClick={handleApprove} />}
            {(allowUpdate) && <><div className="add-seperator"/><HPHButton disabled={isDisable()} label={DOCUMENT_PREVIEW_CONSTANT.REJECT_BUT} size={'Small'} theme={'Secondary'} onClick={handleReject} />
            <HPHButton disabled={isFinalizeDisable()} label={DOCUMENT_PREVIEW_CONSTANT.FINALIZE_BUT} size={'Small'} theme={'Primary'} onClick={handleFinalize} /></>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(DocumentPreviewTitleBar);